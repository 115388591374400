// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-background-tsx": () => import("./../../../src/pages/background.tsx" /* webpackChunkName: "component---src-pages-background-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-logapp-tsx": () => import("./../../../src/pages/logapp.tsx" /* webpackChunkName: "component---src-pages-logapp-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-work-process-tsx": () => import("./../../../src/pages/work-process.tsx" /* webpackChunkName: "component---src-pages-work-process-tsx" */),
  "component---src-templates-calendar-tsx": () => import("./../../../src/templates/calendar.tsx" /* webpackChunkName: "component---src-templates-calendar-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/cms-page.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-documents-tsx": () => import("./../../../src/templates/documents.tsx" /* webpackChunkName: "component---src-templates-documents-tsx" */),
  "component---src-templates-glossary-tsx": () => import("./../../../src/templates/glossary.tsx" /* webpackChunkName: "component---src-templates-glossary-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-law-changes-tsx": () => import("./../../../src/templates/law-changes.tsx" /* webpackChunkName: "component---src-templates-law-changes-tsx" */),
  "component---src-templates-law-tsx": () => import("./../../../src/templates/law.tsx" /* webpackChunkName: "component---src-templates-law-tsx" */),
  "component---src-templates-laws-of-the-game-audio-tsx": () => import("./../../../src/templates/laws-of-the-game-audio.tsx" /* webpackChunkName: "component---src-templates-laws-of-the-game-audio-tsx" */),
  "component---src-templates-laws-of-the-game-documents-tsx": () => import("./../../../src/templates/laws-of-the-game-documents.tsx" /* webpackChunkName: "component---src-templates-laws-of-the-game-documents-tsx" */),
  "component---src-templates-logapp-additional-translation-tsx": () => import("./../../../src/templates/logappAdditionalTranslation.tsx" /* webpackChunkName: "component---src-templates-logapp-additional-translation-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-organisation-tsx": () => import("./../../../src/templates/organisation.tsx" /* webpackChunkName: "component---src-templates-organisation-tsx" */)
}

